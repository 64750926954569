import axios from "axios"
import { signInWithEmailAndPassword } from "firebase/auth"
import { doc, setDoc } from "firebase/firestore"
import React, { useState } from "react"
import { auth, db } from "../../../firebase-config"
import { getUIDMain, getUserToken } from "../../service/user-service"
import { ENABLE_ERROR_LOGGING, ENDPOINT_128, ENDPOINT_512 } from "../../settings/project-settings"
import { createGlobalStyle } from "styled-components"
import { callEndpoint } from "../../service/endpoints-service"
import { QuickLogin, TestBoxBottonizedResponses, TestBoxLine, TestBoxV2 } from "../../client-ui/test/shared"

const WORKING_UID = 'oe7JHprqoAUe09o66gxgftFHvyr2'

export const GlobalStyle = createGlobalStyle`
*{
  margin:0;
  padding:0;
}
html, body {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    background-color : whitesmoke;
  }
  body {
    position: relative;
    width: 100%;
  }
  img{
    max-width:100%;
 }
`;



export default() => {
    const [currentUser, setCurrentUser] = React.useState("NOT LOGGED IN!")
    const [token, setToken] = React.useState("NOT LOGGED IN!")

    const [read, setRead] = React.useState("-")
    React.useEffect(()=>{
        auth.onAuthStateChanged(()=>{
            if( auth.currentUser ) setCurrentUser(auth.currentUser.uid)
            else setCurrentUser("failed to login")
            
            let anyAuth : any = auth
            if( anyAuth?.currentUser?.accessToken )
                setToken( anyAuth.currentUser.accessToken )
            
        })
        
    })


    
    return (
        <>
            <GlobalStyle/>

            <div> logged in as: {currentUser}</div>
            <div> logged in as: {JSON.stringify((auth as any)?.currentUser?.email)}</div>
            
            <QuickLogin >
                Login
            </QuickLogin>
            
            
            
            <TestBoxV2 defaultUid={WORKING_UID} title="Get User Custom Claims" onClick={ async ( setResponse : any, field ?: string )=>{

            
                const response : any = await callEndpoint({ 
                    key : "admin-get-user-custom-claims-by-uid", 
                    token : token,
                    endpoint : ENDPOINT_128,
                    params : {
                        targetUid : WORKING_UID
                    },
                })

        

                setResponse(<>
                    
                    <TestBoxLine>
                        {`uid: ${getUIDMain()}`}
                    </TestBoxLine>
                    
                    <TestBoxLine>

                        {
                             Object.keys(response.data.userRecord).map(( keyValue : string )=>{
                          
                                return <>
                                    <br/>
                                    {keyValue} : { JSON.stringify( response.data.userRecord[keyValue] )}
                                    <br/>
                                </>
                            })
                            
                        }
                   
                        <br/>

                        
                    </TestBoxLine>

                    <TestBoxLine>

                        {
                           JSON.stringify( response.data.userRecord.customClaims )
                            
                        }

                        <br/>
                        <br/>


                    </TestBoxLine>



                </>)

            }}/>




            <TestBoxV2 field defaultUid={WORKING_UID} title="Set User Custom Claims" onClick={ async ( setResponse : any, field ?: string )=>{

                
                const targetUid : string = WORKING_UID
                
                const object : any = JSON.parse(field as string)

                const response : any = await callEndpoint({ 
                    key : "admin-set-user-custom-claims-by-uid", 
                    token : token,
                    endpoint : ENDPOINT_128,
                    params : {
                        targetUid,
                        updatedTargetCustomClaims : object
                    },
                })

        

                setResponse(<>
                    
                    <TestBoxLine>
                        {`uid: ${getUIDMain()}`}
                    </TestBoxLine>

                    <TestBoxLine>
                        {`submitting to ${targetUid}: ${JSON.stringify(field)} `}
                    </TestBoxLine>
                    
                    <TestBoxLine>

                        {
                             JSON.stringify({"paid":2,"createdData":true,"currentCard":"card0","createdOn":"2023-07-17T08:24:20.207Z","access":"valid","history":[],"temporaryBan":false,"id":"cus_OHLQfAWMNPBxQO","uidStorage00":"pU78MmGz88XsHg7n9EAQ0wwADQw1","isMissingDataError":1,"pages":["testing-page-03"],"changeVersion":7})
                            
                        }
                   
                        <br/>

                        
                    </TestBoxLine>

                     <TestBoxLine>

                        {
                             JSON.stringify(object)
                            
                        }
                   
                        <br/>

                        
                    </TestBoxLine>

                   



                </>)

            }}/>




            <TestBoxV2 field defaultUid={WORKING_UID} title="Explore User Data by UID" onClick={ async ( setResponse : any, field ?: string )=>{

                if( ENABLE_ERROR_LOGGING ) console.log( field )
                //const targetUid : string = 'oe7JHprqoAUe09o66gxgftFHvyr2'
                
                

                const response : any = await callEndpoint({ 
                    key : "admin-get-user-data-by-uid", 
                    token : token,
                    endpoint : ENDPOINT_128,
                    params : {
                        targetUid : field
                    },
                })

        

                setResponse(<>
                    
                    <TestBoxLine>
                        {`uid: ${getUIDMain()}`}
                    </TestBoxLine>

                    <TestBoxLine>
                        {`submitting to: ${field} `}
                    </TestBoxLine>

                    {/* <TestBoxLine>
                        {`response ${targetUid}: ${JSON.stringify(response.data)} `}
                    </TestBoxLine> */}
                    

                    <TestBoxBottonizedResponses initialResponse={response.data}/>
                    
                   

                   



                </>)

            }}/>


            <TestBoxV2 field defaultUid={WORKING_UID} title="Regen User Social Share by UID" onClick={ async ( setResponse : any, field ?: string )=>{

                if( ENABLE_ERROR_LOGGING ) console.log( field )
                //const targetUid : string = 'oe7JHprqoAUe09o66gxgftFHvyr2'
                const values : string[] = field?.split(',') as any
                
                if(values.length < 1) return alert('no value for pageId provided (uid,pageId)')
                const targetUid = values[0]
                const targetCardId = values[1]

                if( ENABLE_ERROR_LOGGING ) console.log(`targetUid: ${targetUid}`)
                if( ENABLE_ERROR_LOGGING ) console.log(`targetCardId: ${targetCardId}`)

                const response : any = await callEndpoint({ 
                    key : "admin-regen-user-social-share-by-uid", 
                    token : token,
                    endpoint : ENDPOINT_512,
                    params : {
                        targetUid,
                        targetCardId
                    },
                })

        

                setResponse(<>
                    
                    <TestBoxLine>
                        {`uid: ${getUIDMain()}`}
                    </TestBoxLine>

                    <TestBoxLine>
                        {`submitting to: ${targetUid} `}
                    </TestBoxLine>

                    <TestBoxLine>
                        {`response ${targetUid}: ${JSON.stringify(response.data)} `}
                    </TestBoxLine>
   
                </>)

            }}/>


            <TestBoxV2 field defaultUid={WORKING_UID} title="Read User Claims" onClick={ async ( setResponse : any, field ?: string )=>{

                if( ENABLE_ERROR_LOGGING ) console.log( field )
                //const targetUid : string = 'oe7JHprqoAUe09o66gxgftFHvyr2'
                const values : string[] = field?.split(',') as any
                
                if(values.length < 1) return alert('no value for pageId provided (uid,pageId)')
                const targetUid = values[0]
                const targetCardId = values[1]

                if( ENABLE_ERROR_LOGGING ) console.log(`targetUid: ${targetUid}`)
                if( ENABLE_ERROR_LOGGING ) console.log(`targetCardId: ${targetCardId}`)

                const response : any = await callEndpoint({ 
                    key : "admin-regen-user-social-share-by-uid", 
                    token : token,
                    endpoint : ENDPOINT_512,
                    params : {
                        targetUid,
                        targetCardId
                    },
                })

        

                setResponse(<>
                    
                    <TestBoxLine>
                        {`uid: ${getUIDMain()}`}
                    </TestBoxLine>

                    <TestBoxLine>
                        {`submitting to: ${targetUid} `}
                    </TestBoxLine>

                    <TestBoxLine>
                        {`response ${targetUid}: ${JSON.stringify(response.data)} `}
                    </TestBoxLine>
   
                </>)

            }}/>
        </>
    )
}
