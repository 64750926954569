import axios from "axios"
import { signInWithEmailAndPassword } from "firebase/auth"
import { doc, setDoc } from "firebase/firestore"
import React, { useState } from "react"
import { auth, db } from "../../../firebase-config"
import { ENABLE_ERROR_LOGGING, ENDPOINT_128, ENDPOINT_512 } from "../../settings/project-settings"
import { createGlobalStyle } from "styled-components"
import { LineDividerSpacer } from "../../locust/components/LineDivider"
import { GridRow } from "../../pages/dashboard"
import { getFontBebasNeue } from "../../assets/fonts/FontBebasNeue"
import {ChevronDown} from '@styled-icons/boxicons-solid/ChevronDown'
import {ChevronUp} from '@styled-icons/boxicons-solid/ChevronUp'
import X from "../../locust/composables/X"
import { TextField } from "@mui/material"
import XY from "../../locust/composables/XY"
//import SpacerV from "../src/locust/sections/SpacerV"

const DEFAULT_MINIMIZATION_SETTING : boolean = true 

export const TestBoxV2 = ( props : { 
    title ?: string, 
    children ?: any, 
    minimize ?: boolean, 
    field ?: boolean, 
    defaultUid : string
    onClick : ( setReponse:(value:string)=>void,  field?:string ) => any  
}) => {
    const [ response, setReponse ] = useState("")
    const [ field, setField ] = useState( props?.defaultUid )
    const [ minimize, setMinimize ] = useState( props.minimize !== undefined ? props.minimize : DEFAULT_MINIMIZATION_SETTING )
    
    return (
        <>
            <div style={{ width : '100%', textAlign : 'center', marginTop : 15, marginBottom : 15}}>
                <div style={{ display : 'inline-block', width : '100%', borderRadius : 10, maxWidth : 800, minWidth : 350-20, paddingLeft : 10, paddingRight : 10, backgroundColor: 'white' , minHeight : 100}}>
                    <div style={{ height : 90, cursor : 'pointer' }} onClick={()=>setMinimize(!minimize)}>
                        <div style={{ display : 'inline-block', height : 70, width : 'calc(100% - 70px)'}}>
                            <X>
                                <p style={{ ...getFontBebasNeue(), marginLeft : 70/2, marginTop : 35, fontWeight : 600, fontSize : 22 }}>{props.title}</p>
                            </X>
                        </div>
                        <div style={{ width : 70, height : 70, display : 'inline-block'}}>
                            <XY>
                                <div style={{ marginTop : -4 }}>
                                    { minimize ? <ChevronDown size={30}/> : <ChevronUp size={30}/>  }
                                </div>
                            </XY>
                        </div>
                    </div>
                    { !minimize && <>

                        
                            { props.field && 
                                <div style={{ width : '100%', padding: 20 }}>
                                    <LineDividerSpacer style={{ width : '100%', backgroundColor : 'rgba(0,0,0,0.075)'}}/>
                                    <TextField multiline value={field}  onChange={( event )=>{
                                                setField(event.target.value)
                                    }} style={{ marginLeft : -40,width : 'calc(100% - 60px)'}}/>
                                </div>
                            }
                        

                        <LineDividerSpacer style={{ marginTop : 10, width : '100%', backgroundColor : 'rgba(0,0,0,0.075)'}}/>
                        <div style={{ height : 100, width : '100%'}}>
                            <GridRow height={100} style={{ marginTop : -10 }}>
                                <QuickButton onClick={() => {
                                    if(props.onClick) props.onClick( setReponse, field )
                                }}>
                                    Submit
                                </QuickButton>
                            </GridRow>
                        </div>
                        <LineDividerSpacer style={{ width : '100%', backgroundColor : 'rgba(0,0,0,0.075)'}}/>
                        <div style={{ minHeight : 70, width : '100%', paddingTop : 20 }}>
                            { response }
                        </div>
                    </>}
                </div>
            </div>
        </>
    )
}


export const getObjectPrintedByLine = ( obj : any ) => {
    return <>

        {
                Object.keys(obj).map(( keyValue : string )=>{
            
                return <>
                    <br/>
                    {keyValue} : { JSON.stringify( obj[keyValue] )}
                    <br/>
                </>
            })
            
        }
    </>
}


export const QuickButton = ( props : { 
    children ?: any, 
    onClick ?: any 
}) => {
    
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ minWidth : 250, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={props.onClick}>
            {props.children}
            </button>
        
        </>

    )
}

export const TestBoxLine = ( props : { children ?: any }) => <div>
    <div style={{ paddingTop : 10, minHeight : 50, width : 'calc(100% - 20px)', textAlign : 'left',paddingLeft : 10, paddingRight : 10 }}>{props.children} </div>
    <LineDividerSpacer style={{ width : '100%', backgroundColor : 'rgba(0,0,0,0.075)'}}/>
</div>


export const TestBoxBottonizedResponses = ( props : { initialResponse : any }) => {

    const [response, setResponse ] = useState(props.initialResponse)

    const [showData, setShowData ] = useState<any>()

    const [regenId, setRegenId ] = useState<any>()

    if( ENABLE_ERROR_LOGGING ) console.log(response)
    
    return (
        <>  
            
            <QuickButton  onClick={() => {

                setResponse( props.initialResponse );
                setShowData( undefined )


            }}>
                Reset
            </QuickButton>
            <QuickButton  onClick={() => {

                setShowData( JSON.stringify(response) );
                
                
            }}>
                 Show Data
            </QuickButton>

            <LineDividerSpacer style={{ marginTop : 20, width : '100%', backgroundColor : 'rgba(0,0,0,0.075)'}}/>
            
            { Object.keys((response as any)).map(( keyValue : string, index : number )=>{

                if( typeof (response as any)[keyValue] !== 'object') return <>
                    <br/>
                    <br/>
                     {keyValue} : {(response as any)[keyValue]}
                    <br/>
                </>

                return  ( 
                    
                    <>
                        <QuickButton key={index} onClick={() => {

                            if( ENABLE_ERROR_LOGGING ) console.log(response[keyValue])

                            setRegenId( keyValue )
                            
                            setResponse(response[keyValue])
                        }}>
                            {keyValue}
                        </QuickButton>

                        {/* <>{ JSON.stringify(response[keyValue]) }</> */}
                    </>
                )

                })} 
                <br/>
                <br/>

                <> { showData !== undefined &&  <TestBoxLine>

                    {showData}
                </TestBoxLine> } </>

                <br/>
                <br/>

                

        </>
    )
}



export const QuickLogin = ( props : { 
    children ?: any 
}) => {
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={ ()=>{
                signInWithEmailAndPassword(auth,"newuser-test@email.com", "password").then(( res )=>{
                    alert("success!")
                    if( ENABLE_ERROR_LOGGING ) console.log(res)
                })
            }}>
            {props.children}
        </button>
        
        </>
    )
}
